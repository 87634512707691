<template>
  <b-card>
    <!-- media -->
<!--    <b-media no-body>-->
<!--      <b-media-aside>-->
<!--        <b-link>-->
<!--          <b-img-->
<!--            ref="previewEl"-->
<!--            rounded-->
<!--            :src="user.avatar || avatar"-->
<!--            height="100"-->
<!--          />-->
<!--        </b-link>-->
<!--        &lt;!&ndash;/ avatar &ndash;&gt;-->
<!--      </b-media-aside>-->


<!--    </b-media>-->

    <!--/ media -->


    <validation-observer ref="profileValidation">
      <b-form v-on:submit.prevent="validationForm" class="mt-2">
        <b-row>
          <b-col cols="12">
            <b-media no-body>
              <b-media-aside>
                <b-link>
                  <b-img
                      ref="previewEl"
                      rounded
                      :src="user.avatar"
                      height="80"
                  />
                </b-link>
                <!--/ avatar -->
              </b-media-aside>

              <b-media-body class="mt-75 ml-75">
                <!-- upload button -->
                <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="secondary"
                    size="sm"
                    class="mb-75 mr-75"
                    @click="$refs.refInputEl.$el.click()"
                >
                  Upload
                </b-button>
                <validation-provider
                    #default="{ errors }"
                    name="avatar"
                    rules="size:1024|mimes:image/*"
                    vid="avatar"
                >
                  <b-form-file
                      ref="refInputEl"
                      v-model="avatar"
                      accept=".jpg, .png, .gif"
                      :hidden="true"
                      plain
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>

                <!--/ upload button -->


                <b-card-text>Allowed JPG, GIF or PNG. Max size of 1024kB</b-card-text>
              </b-media-body>
            </b-media>
<!--            <b-form-group label="Avatar" label-for="avatar">
              <validation-provider
                #default="{ errors }"
                name="avatar"
                rules="size:1024|mimes:image/*"
                vid="avatar"
              >
                <b-form-file
                  id="avatar"
                  v-model="user.avatar"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Choose a file or drop it here..."
                  drop-placeholder="Drop file here..."
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>-->
          </b-col>


          <b-col cols="6">
            <br>
            <b-form-group label="Name" label-for="name">
              <validation-provider
                #default="{ errors }"
                name="name"
                vid="name"
                rules="max:255"
              >
                <b-form-input
                  id="name"
                  type="text"
                  v-model="user.name"
                  :state="errors.length > 0 ? false : null"
                  name="name"
                  placeholder="John Doe"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- email -->
          <b-col cols="6">
            <br>
            <b-form-group label="Email" label-for="email">
              <validation-provider
                #default="{ errors }"
                name="email"
                rules="email"
                vid="email"
              >
                <b-form-input
                  id="email"
                  v-model="user.email"
                  :state="errors.length > 0 ? false : null"
                  name="email"

                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- submit  -->
          <b-col cols="12">
            <!-- loading button -->
            <template v-if="isProfileGeneralFormSubmitLoading">
              <b-button class="float-left" variant="secondary" disabled>
                <b-spinner small />
                Loading...
              </b-button>
            </template>

            <!-- submit button -->
            <template v-else>
              <b-button
                type="submit"
                class="float-left"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="secondary"
              >
                Submit
              </b-button>
            </template>
          </b-col>

        </b-row>
      </b-form>
    </validation-observer>

<!--     <b-row>
       User Info: Left col
       <b-col
        cols="21"
        xl="6"
        class="d-flex justify-content-between flex-column"
      >
         User Avatar & Action Buttons
         <div class="d-flex justify-content-start">
          <b-avatar :src="user?.avatar || avatar" size="104px" rounded />
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-0">{{ user?.name }}</h4>
              <span class="card-text">{{ user?.email }}</span>
            </div>
          </div>
        </div>
      </b-col>

       Right Col: Table
       <b-col cols="12" xl="6">
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <feather-icon icon="UserIcon" class="mr-75" />
              <span class="font-weight-bold">Name</span>
            </th>
            <td class="pb-50">{{ user?.name }}</td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="CheckIcon" class="mr-75" />
              <span class="font-weight-bold">Status</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ user?.status ? "Active" : "Inactive" }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="UserCheckIcon" class="mr-75" />
              <span class="font-weight-bold">Role</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ user?.roles?.data[0]?.name }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="AwardIcon" class="mr-75" />
              <span class="font-weight-bold">Designation</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ user?.designation?.data?.name }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="PackageIcon" class="mr-75" />
              <span class="font-weight-bold">Department</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ user?.department?.data?.name }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="FlagIcon" class="mr-75" />
              <span class="font-weight-bold">Country</span>
            </th>
            <td class="pb-50"></td>
          </tr>
          <tr>
            <th>
              <feather-icon icon="PhoneIcon" class="mr-75" />
              <span class="font-weight-bold">Contact</span>
            </th>
            <td></td>
          </tr>
        </table>
      </b-col>
     </b-row> -->
  </b-card>
</template>

<script>
import { mapGetters } from "vuex";
import Ripple from 'vue-ripple-directive'
import { BCard, BButton, BAvatar, BRow, BCol, BFormFile, BForm, BFormGroup, BFormInput, BAlert, BCardText, BMedia, BMediaAside, BMediaBody, BLink, BImg, BSpinner,} from "bootstrap-vue";
// import { avatarText } from "@core/utils/filter";
// import useUsersList from "../users-list/useUsersList";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { email, max, mimes, size } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'


export default {
  components: {
    BCard, BButton, BAvatar, BRow, BCol, BFormFile, BForm, BFormGroup, BFormInput, BAlert, BCardText, BMedia, BMediaAside, BMediaBody, BLink, BImg,ValidationProvider,
    ValidationObserver, BSpinner,
  },
  data() {
    return {
      //email:"",
      //name: "",
      avatar: null,
      isProfileGeneralFormSubmitLoading: false,
    };
  },
  directives:{
    Ripple,
  },
  computed: {
    ...mapGetters({
      user: "userModule/getUser",
    })
  },
  setup() {
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl)

    return {
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
  methods: {

    resetForm() {
      this.name = "";
      this.email = "";
      this.avatar = null;
    },
    validationForm: async function () {


      this.$refs.profileValidation.validate().then(async (success) => {
        if (success) {

          try {
            const formData = new FormData();

            formData.append("_method", "PATCH");

            if (this.avatar) {
              formData.append("avatar", this.avatar);
            }

            if (Object.keys(this.user.name).length != 0) {
              formData.append("name", this.user.name);
            }

            if (Object.keys(this.user.email).length != 0) {
              formData.append("email", this.user.email);
            }

            this.isProfileGeneralFormSubmitLoading = true;
            const res = await this.$api.post(
              "/api/user/profile/general/update",
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            );

            this.isProfileGeneralFormSubmitLoading = false;

            const data = res?.data?.data;

            await this.$store.dispatch("userModule/setUser", {
              user: data,
            });

            //this.resetForm();

            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Success",
                icon: "BellIcon",
                variant: "success",
                text: "Profile successfully updated",
              },
            });
          } catch (error) {
            this.isProfileGeneralFormSubmitLoading = false;

            if (error?.response?.data?.message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  icon: "BellIcon",
                  variant: "danger",
                  text: error?.response?.data?.message,
                },
              });
            }

            if (error?.response?.data?.errors) {
              this.$refs.profileGeneralValidation.setErrors(
                error?.response?.data?.errors
              );
            }
          }
        }
      });
    },
  },
};

</script>
