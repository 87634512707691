var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', [_c('validation-observer', {
    ref: "profileValidation"
  }, [_c('b-form', {
    staticClass: "mt-2",
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.validationForm($event);
      }
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-media', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-media-aside', [_c('b-link', [_c('b-img', {
    ref: "previewEl",
    attrs: {
      "rounded": "",
      "src": _vm.user.avatar,
      "height": "80"
    }
  })], 1)], 1), _c('b-media-body', {
    staticClass: "mt-75 ml-75"
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "mb-75 mr-75",
    attrs: {
      "variant": "secondary",
      "size": "sm"
    },
    on: {
      "click": function click($event) {
        return _vm.$refs.refInputEl.$el.click();
      }
    }
  }, [_vm._v(" Upload ")]), _c('validation-provider', {
    attrs: {
      "name": "avatar",
      "rules": "size:1024|mimes:image/*",
      "vid": "avatar"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('b-form-file', {
          ref: "refInputEl",
          attrs: {
            "accept": ".jpg, .png, .gif",
            "hidden": true,
            "plain": ""
          },
          model: {
            value: _vm.avatar,
            callback: function callback($$v) {
              _vm.avatar = $$v;
            },
            expression: "avatar"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  }), _c('b-card-text', [_vm._v("Allowed JPG, GIF or PNG. Max size of 1024kB")])], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('br'), _c('b-form-group', {
    attrs: {
      "label": "Name",
      "label-for": "name"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "name",
      "vid": "name",
      "rules": "max:255"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var errors = _ref2.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "name",
            "type": "text",
            "state": errors.length > 0 ? false : null,
            "name": "name",
            "placeholder": "John Doe"
          },
          model: {
            value: _vm.user.name,
            callback: function callback($$v) {
              _vm.$set(_vm.user, "name", $$v);
            },
            expression: "user.name"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('br'), _c('b-form-group', {
    attrs: {
      "label": "Email",
      "label-for": "email"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "email",
      "rules": "email",
      "vid": "email"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var errors = _ref3.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "email",
            "state": errors.length > 0 ? false : null,
            "name": "email"
          },
          model: {
            value: _vm.user.email,
            callback: function callback($$v) {
              _vm.$set(_vm.user, "email", $$v);
            },
            expression: "user.email"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_vm.isProfileGeneralFormSubmitLoading ? [_c('b-button', {
    staticClass: "float-left",
    attrs: {
      "variant": "secondary",
      "disabled": ""
    }
  }, [_c('b-spinner', {
    attrs: {
      "small": ""
    }
  }), _vm._v(" Loading... ")], 1)] : [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "float-left",
    attrs: {
      "type": "submit",
      "variant": "secondary"
    }
  }, [_vm._v(" Submit ")])]], 2)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }